import React from "react";
import { useParams } from "react-router-dom";

import { uploadImage } from "../../utils/uploadFileFunc";
import Button from "./Button";
import firebase, { db } from "../../firebase";
import { LOCATION_AMERICA, LOCATION_NIGERIA } from "../../constant";

function Form() {
	const { country } = useParams();
	const [imageFile, setImageFile] = React.useState("");
	const [isFormReady, setIsFormReady] = React.useState(false);
	const [postError, setPostError] = React.useState({
		msg: "",
		status: false,
	});
	const [formData, setFormData] = React.useState({
		text: "",
		price: 0,
		duration: "",
		description: "",
		image: "",
	});
	const inputTypes = [
		{
			id: "text",
			type: "text",
			name: "Title",
			value: "",
			placeholder: "Service title",
		},
		{
			id: "price",
			type: "number",
			name: "Price",
			value: "",
			placeholder: "Service price",
		},
		{
			id: "duration",
			type: "text",
			name: "Duration",
			value: "",
			placeholder: "Service duration",
		},
	];

	const handleformDataChange = (e) => {
		setFormData({ ...formData, [e.target.id]: e.target.value });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (country?.toUpperCase() === LOCATION_AMERICA) {
			try {
				const docRef = db.collection("admin").doc("DglVkrdNucxcnkZHW7vo");
				const updatedService = {
					...formData,
				};

				if (imageFile && imageFile[0]) {
					updatedService.image = imageFile[0];
				}

				// Update the array field using arrayUnion
				await docRef.update({
					services: firebase.firestore.FieldValue.arrayUnion(updatedService),
				});

				setFormData({
					text: "",
					price: 0,
					duration: "",
					description: "",
					image: imageFile,
				});

				console.log(`${"service field"} Field updated successfully!`);
			} catch (error) {
				setPostError({
					status: true,
					msg: "Error: Please contact admin !",
				});
				console.error(`Error updating ${"service field"} field:`, error);
			}
		}

		if (country?.toUpperCase() === LOCATION_NIGERIA) {
			try {
				const docRef = db.collection("adminNG").doc("42vAo2b93dk2K8JA7oZE");
				const updatedService = {
					...formData,
				};

				if (imageFile && imageFile[0]) {
					updatedService.image = imageFile[0];
				}

				// Update the array field using arrayUnion
				await docRef.update({
					services: firebase.firestore.FieldValue.arrayUnion(updatedService),
				});
				setFormData({
					text: "",
					price: 0,
					duration: "",
					description: "",
					image: imageFile,
				});

				console.log(`${"service field"} Field updated successfully!`);
			} catch (error) {
				setPostError({
					status: true,
					msg: "Error: Please contact admin !",
				});
				console.error(`Error updating ${"service field"} field:`, error);
			}
		}
	};

	return (
		<form onSubmit={handleSubmit} className="flex flex-col items-center mt-5">
			{inputTypes.map((inputType) => (
				<input
					key={inputType.id}
					type={inputType.type}
					name={inputType.name}
					id={inputType.id}
					value={formData[inputType.id]}
					onChange={handleformDataChange}
					placeholder={inputType.placeholder}
					className="rounded mt-1 block mx-auto w-[90%] px-3 py-2 border-none text-base shadow-xl placeholder-gray-400 focus:outline-none focus:border-gray-200 focus:ring-1 focus:ring-gray-200 isabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none invalid:border-orange-500 invalid:text-orange-600 focus:invalid:border-orange-500 focus:invalid:ring-orange-500 outline-0 mb-5 placeholder:font-light placeholder:text-xs text-gray-700 font-light"
				/>
			))}
			<textarea
				id="description"
				value={formData.description}
				name="description"
				onChange={handleformDataChange}
				rows={10}
				cols={50}
				placeholder="Description..."
				className="rounded mt-1 block mx-auto w-[90%] px-3 py-2 border-none text-base shadow-xl placeholder-gray-400 focus:outline-none focus:border-gray-200 focus:ring-1 focus:ring-gray-200 isabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none invalid:border-orange-500 invalid:text-orange-600 focus:invalid:border-orange-500 focus:invalid:ring-orange-500 outline-0 mb-5 placeholder:font-light placeholder:text-xs text-gray-700 font-light"
			/>
			<div className="flex flex-row items-center py-2 pl-3 rounded shadow-xl">
				<label className="mr-1 text-xs text-gray-500">Images</label>
				<input
					type="file"
					multiple
					onChange={(e) => {
						uploadImage(e, setImageFile);

						const readyid = setTimeout(() => {
							setIsFormReady(true);
						}, 1000);

						return () => clearImmediate(readyid);
					}}
					className="block w-full text-sm text-gray-500 duration-300 ease-in file:mr-4 file:py-1 file:px-4 file:rounded file:border-0 file:text-sm file:bg-gray-200 file:text-cyan-700 hover:file:bg-cyan-100"
				/>
			</div>

			{postError.status && (
				<span className="mt-5 text-xs text-orange-700">{postError.msg}</span>
			)}
			{isFormReady && (
				<div className="my-10">
					<Button type="submit">{"Add to Service"}</Button>
				</div>
			)}
		</form>
	);
}

export default Form;
